import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AuthService } from './api/auth.service';
import { BusquedaService } from './api/busqueda.service';
import { ContactoService } from './api/contacto.service';
import { DepartamentosService } from './api/departamentos.service';
import { DocumentoService } from './api/documento.service';
import { EPIService } from './api/ePI.service';
import { FAQsService } from './api/fAQs.service';
import { FamiliasService } from './api/familias.service';
import { FiltrosService } from './api/filtros.service';
import { IdiomasService } from './api/idiomas.service';
import { MarcasService } from './api/marcas.service';
import { NoticiasActualidadService } from './api/noticiasActualidad.service';
import { OperariosService } from './api/operarios.service';
import { PaisesService } from './api/paises.service';
import { PuestosService } from './api/puestos.service';
import { ServiciosService } from './api/servicios.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AuthService,
    BusquedaService,
    ContactoService,
    DepartamentosService,
    DocumentoService,
    EPIService,
    FAQsService,
    FamiliasService,
    FiltrosService,
    IdiomasService,
    MarcasService,
    NoticiasActualidadService,
    OperariosService,
    PaisesService,
    PuestosService,
    ServiciosService,
    UserService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
