import {NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {GlobalService} from "./services/global.service";
import {ApiModule, BASE_PATH} from "./rest";
import {environment} from "../environments/environment";
import {JWT_OPTIONS, JwtModule} from "@auth0/angular-jwt";
import {IonicStorageModule, Storage} from "@ionic/storage";
import {from} from "rxjs";
import {strategy} from "@angular-devkit/core/src/experimental/jobs";
import {map, switchMap} from "rxjs/operators";
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {FirebaseService} from "./services/firebase.service";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


import {registerLocaleData, LocationStrategy, HashLocationStrategy} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {ErrorInterceptor} from "./interceptors/error.inteceptor";
import {IonicGestureConfig} from "./services/ionic-gesture-config";
import { AnimacionComponent } from './paginas/web/animacion/animacion.component';

registerLocaleData(localeEs, 'es');

// Configuracion del modulo JWT - Necesario para acceder al modulo de storage de ionic
export function jwtOptionsFactory(storage: Storage) {
    return {
        whitelistedDomains: environment.WHITE_LISTED_DOMAINS,
        headerName: 'Authorization',
        authScheme: 'Bearer ',
        blacklistedRoutes: ['/\/api\/auth\/login\?[A-Z0-9]*/', '/\/api\/auth\/register\?[A-Z0-9]*/', '/\/api\/auth\/password/', '/idiomas$/'],
        // el modulo jwt espera un promise en lugar de un observable
        tokenGetter: async () => {
            await storage.ready();
            return storage.get('Authentication');
        }
    }
}


@NgModule({
    declarations: [AppComponent, AnimacionComponent],
    entryComponents: [AnimacionComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({animated: false}),
        AppRoutingModule,
        HttpClientModule,

        ApiModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Storage]
            },
        })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: BASE_PATH, useValue: environment.API_BASE_PATH},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        FirebaseX,
        GlobalService,
        InAppBrowser,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: IonicGestureConfig
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
