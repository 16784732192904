/**
 * Rubix API
 * API para la alimentación de datos de la APP
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent, HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { FiltroEmbudo } from '../model/filtroEmbudo';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { GlobalService } from 'src/app/services/global.service';



@Injectable({
    providedIn: 'root'
})
export class FiltrosService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(private global: GlobalService, protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (this.global.getPais()) {
            basePath = this.global.getPais().url;
        } else if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }

        }
        this.configuration.basePath = basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Devuelve los filtros asociados a un valor (sus hijos)
     * @param listado Códigos de ValorFiltro separados por comas
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFiltroHijosPost(listado: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FiltroEmbudo>>;
    public apiFiltroHijosPost(listado: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FiltroEmbudo>>>;
    public apiFiltroHijosPost(listado: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FiltroEmbudo>>>;
    public apiFiltroHijosPost(listado: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (listado === null || listado === undefined) {
            throw new Error('Required parameter listado was null or undefined when calling apiFiltroHijosPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: this.encoder });
        }

        if (listado !== undefined) {
            formParams = formParams.append('listado', <any>listado) as any || formParams;
        }

        return this.httpClient.post<Array<FiltroEmbudo>>(`${this.configuration.basePath}/api/filtro/hijos`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Devuelve el filtro seleccionado
     * @param id Identificador del filtro
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFiltroIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<FiltroEmbudo>;
    public apiFiltroIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FiltroEmbudo>>;
    public apiFiltroIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FiltroEmbudo>>;
    public apiFiltroIdGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiFiltroIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<FiltroEmbudo>(`${this.configuration.basePath}/api/filtro/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Devuelve el primer nivel de filtros de la familia dada
     * @param id Identificador de la familia
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFiltrosByFamiliaIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<FiltroEmbudo>>;
    public apiFiltrosByFamiliaIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FiltroEmbudo>>>;
    public apiFiltrosByFamiliaIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FiltroEmbudo>>>;
    public apiFiltrosByFamiliaIdGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiFiltrosByFamiliaIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<Array<FiltroEmbudo>>(`${this.configuration.basePath}/api/filtros/by/familia/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
