import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-animacion',
  templateUrl: './animacion.component.html',
  styleUrls: ['./animacion.component.scss'],
})
export class AnimacionComponent implements OnInit {

  constructor(
    public global: GlobalService,
    public router: Router,
    public modal: ModalController
  ) { }

  ngOnInit() {
    const page = this;
    $(document).ready(function () {
      setTimeout(function () {
        page.empiezaAnim();
      }, 1000);
    });
  }

  irInicio() {
    if (this.global.getPlt() === '/web') {
      this.router.navigate([this.global.getPlt() + '/epis']);
    } else {
      this.router.navigate([this.global.getPlt() + '/private']);
    }
    this.modal.dismiss();
  }

  irRegistro() {
    this.router.navigate([this.global.getPlt() + '/registro']);
    this.modal.dismiss();
  }

  empiezaAnim() {
    const ancho = $('.modalAnim .modal-wrapper').outerWidth();
    const alto = $('.modalAnim .modal-wrapper').outerHeight();
    console.log(ancho + "x" + alto);

    if (ancho < 400) {
      $('.content').css('max-width', ancho + 'px');
    }

    let altura = document.getElementById('movil').offsetHeight;
    if (alto < altura) {
      altura = alto;
      $('.animacion img').css('max-height', altura + 'px');
    }
    $('.contenido').css('height', altura + 'px');
    $('.contenido').css('max-height', altura + 'px');
    // tslint:disable: only-arrow-functions
    setTimeout(function () {
      $('.paso2').css('display', 'block').addClass('zoomIn');
    }, 500);
    setTimeout(function () {
      $('.paso3').css('display', 'block').addClass('zoomIn');
    }, 1000);
    setTimeout(function () {
      $('.paso4').css('display', 'block').addClass('rollIn');
    }, 1100);
    setTimeout(function () {
      $('.paso5').css('display', 'block').addClass('rollIn');
    }, 1200);
    setTimeout(function () {
      $('.paso6').css('display', 'block').addClass('rollIn');
    }, 1300);
    setTimeout(function () {
      $('.paso7').css('display', 'block').addClass('bounceInLeft');
      $('.paso8').css('display', 'block').addClass('fadeInRight');
    }, 1400);
    // paso1
    setTimeout(function () {
      $('.paso7').css('display', 'block').addClass('bounceOutLeft');
      $('.paso8').css('display', 'block').addClass('fadeOutRight');
      $('.paso4').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso5').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso6').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
    }, 4400);
    setTimeout(function () {
      $('.paso4').css('display', 'block').addClass('rollIn');
    }, 4500);
    setTimeout(function () {
      $('.paso5').css('display', 'block').addClass('rollIn');
    }, 4600);
    setTimeout(function () {
      $('.paso6').css('display', 'block').addClass('rollIn');
    }, 4700);
    setTimeout(function () {
      $('.paso15').css('display', 'block').addClass('bounceInLeft');
      $('.paso16').css('display', 'block').addClass('fadeInRight');
    }, 4800);
    setTimeout(function () {
      $('.paso1').addClass('paso17animacion');
    }, 3900);
    setTimeout(function () {
      $('.paso17').css('display', 'block').addClass('zoomIn').addClass('paso17animacion');
      $('.paso2').addClass('paso17animacion');
    }, 4900);
    setTimeout(function () {
      $('.paso1').removeClass('paso17animacion').addClass('paso17animacion_invertido');
    }, 8400);
    setTimeout(function () {
      $('.paso15').css('display', 'block').addClass('bounceOutLeft');
      $('.paso16').css('display', 'block').addClass('fadeOutRight');
      $('.paso4').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso5').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso6').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso17').removeClass('paso17animacion').addClass('paso17animacion_invertido').removeClass('zoomIn').addClass('zoomOut');
      $('.paso2').removeClass('paso17animacion').addClass('paso17animacion_invertido');
    }, 9400);
    setTimeout(function () {
      $('.paso4').css('display', 'block').addClass('rollIn');
    }, 9500);
    setTimeout(function () {
      $('.paso5').css('display', 'block').addClass('rollIn');
    }, 9600);
    setTimeout(function () {
      $('.paso6').css('display', 'block').addClass('rollIn');
    }, 9700);
    setTimeout(function () {
      $('.paso9').css('display', 'block').addClass('bounceInLeft');
      $('.paso10').css('display', 'block').addClass('fadeInRight');
    }, 9800);
    setTimeout(function () {
      $('.paso9').css('display', 'block').addClass('bounceOutLeft');
      $('.paso10').css('display', 'block').addClass('fadeOutRight');
      $('.paso4').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso5').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso6').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
    }, 12800);
    setTimeout(function () {
      $('.paso4').css('display', 'block').addClass('rollIn');
    }, 12900);
    setTimeout(function () {
      $('.paso5').css('display', 'block').addClass('rollIn');
    }, 13000);
    setTimeout(function () {
      $('.paso6').css('display', 'block').addClass('rollIn');
    }, 13100);
    setTimeout(function () {
      $('.paso11').css('display', 'block').addClass('bounceInLeft');
      $('.paso12').css('display', 'block').addClass('fadeInRight');
    }, 13200);
    setTimeout(function () {
      $('.paso13').css('display', 'block').addClass('zoomIn');
    }, 13300);
    setTimeout(function () {
      $('.paso2').removeClass('zoomIn').removeClass('paso17animacion_invertido').addClass('zoomOut');
      $('.paso14').css('display', 'block').addClass('fadeInUp');
    }, 14300);
    setTimeout(function () {
      $('.paso14').addClass('paso14animacion');
    }, 14400);
    setTimeout(function () {
      $('.paso14').removeClass('paso14animacion').removeClass('fadeInUp');
    }, 17400);
    setTimeout(function () {
      $('.paso14').addClass('fadeOutDown');
    }, 17500);
    setTimeout(function () {
      $('.paso11').css('display', 'block').addClass('bounceOutLeft');
      $('.paso12').css('display', 'block').addClass('fadeOutRight');
      $('.paso4').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso5').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
      $('.paso6').css('display', 'block').removeClass('rollIn').addClass('rotateOutUpLeft');
    }, 18000);
    setTimeout(function () {
      $('.paso4').css('display', 'block').addClass('rollIn');
    }, 18100);
    setTimeout(function () {
      $('.paso5').css('display', 'block').addClass('rollIn');
    }, 18200);
    setTimeout(function () {
      $('.paso6').css('display', 'block').addClass('rollIn');
    }, 18300);
    setTimeout(function () {
      $('.paso18').css('display', 'block').addClass('bounceInLeft');
      $('.paso19').css('display', 'block').addClass('fadeInRight');
      $('.paso1').removeClass('paso17animacion_invertido').removeClass('bounceInRight');
      $('.paso20').css('display', 'block').addClass('zoomIn');
    }, 18400);
    setTimeout(function () {
      $('.paso1').addClass('paso20animacion');
      $('.paso13').addClass('paso20animacion');
      $('.paso20').addClass('paso20animacion');
    }, 19400);
    setTimeout(function () {
      $('.paso14').addClass('paso21_posicion');
      $('.paso14').addClass('paso14posicion').removeClass('fadeOutDown').addClass('fadeInUp');
    }, 19500);
    setTimeout(function () {
      $('.paso14').addClass('paso21animacion');
      $('.paso21').css('display', 'block').addClass('paso22animacion');
    }, 20500);
    console.log("hola4");
  }
}
