import {Injectable} from '@angular/core';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {AuthenticationService} from "./authentication.service";
import {UserService} from "../rest";
import {from, of, Observable} from "rxjs";
import {switchMap, tap} from "rxjs/operators";
import {ToastController, Platform} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(
        public firebaseX: FirebaseX,
        public userService: UserService,
        public toastController: ToastController,
        public platform: Platform
    ) {

    }

    private subscribed = false;

    getToken() {
        return from(this.firebaseX.getToken())
    }


    listenToNotifications() {
        return this.firebaseX.onMessageReceived()
    }


    saveToken(): Observable<any> {
        if (!this.subscribed) {
            return from(this.platform.ready()).pipe(
                switchMap(() => {
                    if (this.platform.is('ios') || this.platform.is('android')) {
                        return from(this.firebaseX.grantPermission())
                    }
                    return of(null)
                }),
                switchMap((perm) => {
                    if (perm === true) {
                        return this.subscribe();
                    }
                    return of(perm);
                })
            )
        }
        return of();
    }


    subscribe() {
        return this.getToken()
            .pipe(switchMap(firebaseToken => {
                    return this.userService.apiAuthUserDispositivoPost(firebaseToken)
                })
                , switchMap(() => {
                    this.subscribed = true;
                    return this.listenToNotifications()
                }),
                tap(
                    msg => {  // show a toast
                        this.toastController.create({
                            message: msg.body,
                            duration: 3000,
                            position: 'top'
                        }).then(t => t.present());
                    }
                )
            )
    }


}
