/**
 * Rubix API
 * API para la alimentación de datos de la APP
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent, HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { GlobalService } from 'src/app/services/global.service';



@Injectable({
    providedIn: 'root'
})
export class ContactoService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(private global: GlobalService, protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (this.global.getPais()) {
            basePath = this.global.getPais().url;
        } else if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }

        }
        this.configuration.basePath = basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Recoge los contactos desde una epi EPI
     * @param idProvincia Identificador de la Provincia
     * @param idEpi Identificador del Epi
     * @param texto texto a guardr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContactoPost(idProvincia: number, idEpi: number, texto: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiContactoPost(idProvincia: number, idEpi: number, texto: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiContactoPost(idProvincia: number, idEpi: number, texto: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiContactoPost(idProvincia: number, idEpi: number, texto: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (idProvincia === null || idProvincia === undefined) {
            throw new Error('Required parameter idProvincia was null or undefined when calling apiContactoPost.');
        }
        if (idEpi === null || idEpi === undefined) {
            throw new Error('Required parameter idEpi was null or undefined when calling apiContactoPost.');
        }
        if (texto === null || texto === undefined) {
            throw new Error('Required parameter texto was null or undefined when calling apiContactoPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: this.encoder });
        }

        if (idProvincia !== undefined) {
            formParams = formParams.append('idProvincia', <any>idProvincia) as any || formParams;
        }
        if (idEpi !== undefined) {
            formParams = formParams.append('idEpi', <any>idEpi) as any || formParams;
        }
        if (texto !== undefined) {
            formParams = formParams.append('texto', <any>texto) as any || formParams;
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/contacto`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
