/**
 * Rubix API
 * API para la alimentación de datos de la APP
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent, HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { Pais } from '../model/pais';
import { Provincia } from '../model/provincia';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { GlobalService } from 'src/app/services/global.service';



@Injectable({
    providedIn: 'root'
})
export class PaisesService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(private global: GlobalService, protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (this.global.getPais()) {
            basePath = this.global.getPais().url;
        } else if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }

        }
        this.configuration.basePath = basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    /**
     * Devuelve el pais dado
     * @param id Identificador del pais
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicPaisIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Pais>;
    public apiPublicPaisIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Pais>>;
    public apiPublicPaisIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Pais>>;
    public apiPublicPaisIdGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPublicPaisIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<Pais>(`${this.configuration.basePath}/api/public/pais/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Devuelve el listado de paises
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicPaisesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Pais>>;
    public apiPublicPaisesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Pais>>>;
    public apiPublicPaisesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Pais>>>;
    public apiPublicPaisesGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<Array<Pais>>(`${this.configuration.basePath}/api/public/paises`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Devuelve el listado de provincias de un pais dado
     * @param id Identificador del pais
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicProvinciasByPaisIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Provincia>>;
    public apiPublicProvinciasByPaisIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Provincia>>>;
    public apiPublicProvinciasByPaisIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Provincia>>>;
    public apiPublicProvinciasByPaisIdGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPublicProvinciasByPaisIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<Array<Provincia>>(`${this.configuration.basePath}/api/public/provincias/by/pais/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
