export * from './auth.service';
import { AuthService } from './auth.service';
export * from './busqueda.service';
import { BusquedaService } from './busqueda.service';
export * from './contacto.service';
import { ContactoService } from './contacto.service';
export * from './departamentos.service';
import { DepartamentosService } from './departamentos.service';
export * from './documento.service';
import { DocumentoService } from './documento.service';
export * from './ePI.service';
import { EPIService } from './ePI.service';
export * from './fAQs.service';
import { FAQsService } from './fAQs.service';
export * from './familias.service';
import { FamiliasService } from './familias.service';
export * from './filtros.service';
import { FiltrosService } from './filtros.service';
export * from './idiomas.service';
import { IdiomasService } from './idiomas.service';
export * from './marcas.service';
import { MarcasService } from './marcas.service';
export * from './noticiasActualidad.service';
import { NoticiasActualidadService } from './noticiasActualidad.service';
export * from './operarios.service';
import { OperariosService } from './operarios.service';
export * from './paises.service';
import { PaisesService } from './paises.service';
export * from './puestos.service';
import { PuestosService } from './puestos.service';
export * from './servicios.service';
import { ServiciosService } from './servicios.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AuthService, BusquedaService, ContactoService, DepartamentosService, DocumentoService, EPIService, FAQsService, FamiliasService, FiltrosService, IdiomasService, MarcasService, NoticiasActualidadService, OperariosService, PaisesService, PuestosService, ServiciosService, UserService];
