import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    public noticiasPendientes = 0;
    public noticiasPendientesArray = [];
    public language = '';
    public titulo = '';
    public fichaCargada = false;

    constructor(
        public _translate: TranslateService,
        private platform: Platform,
        private iab: InAppBrowser
    ) {
        const page = this;
        // PARA TODOS LOS ENLACES DE LA APP QUE QUEREMOS QUE SE LANCEN FUERA.
        document.body.addEventListener('click', event => {
            if (event != null && typeof event.target !== 'undefined') {
                const elem = event.target as HTMLTextAreaElement;
                if (elem.getAttribute('target') === '_system' || elem.getAttribute('target') === '_blank') {
                    event.preventDefault();
                    const url = elem.getAttribute('href');
                    const options: InAppBrowserOptions = {
                        clearcache: 'yes',
                        clearsessioncache: 'yes',
                        toolbarcolor: '#494949',
                        location: 'yes',
                        hidenavigationbuttons: 'yes',
                        hideurlbar: 'yes',
                        closebuttoncaption: 'Cerrar',
                        footer: 'no',
                        hidden: 'no',
                        zoom: 'yes',
                        hardwareback: 'yes',
                        mediaPlaybackRequiresUserAction: 'no',
                        shouldPauseOnSuspend: 'no',
                        disallowoverscroll: 'no',
                        toolbar: 'yes',
                        enableViewportScale: 'yes',
                        allowInlineMediaPlayback: 'yes',
                        presentationstyle: 'pagesheet',
                        fullscreen: 'yes'
                    };

                    let browserExterno = null;
                    browserExterno = this.iab.create(url, '_blank', options);
                }
            }
        });
    }

    actualizarTraduccion(titulo) {
        // Si no se ha inicializado la var language:
        if (this.language == '') {
            // Ponemos el idioma por defecto a castellano en la primera pantalla:
            this._translate.setDefaultLang('es');
            // Leemos el idioma del navegador y lo set-eamos para su uso:
            this.language = this._translate.getBrowserLang();
            console.log('Idioma: ' + this.language);
        }
        this._translate.use(this.language);

        // "TITULOS"
        this.titulo = titulo;
        this._translate.get(this.titulo).subscribe((res: string) => {
            this.titulo = res;
        });
    }

    getPlt() {
        if (this.platform.is('android') || this.platform.is('ios')) {
            return '/app';
        } else {
            // #TODO :: Esto descomentarlo
            return '/web';
            // return '/app';
        }
    }
    getPais() {
        if (localStorage.getItem('paisSelected')) {
            return JSON.parse(localStorage.getItem('paisSelected'));
        } else {
            return null;
        }
    }
    setPais(pais) {
        localStorage.setItem('paisSelected', JSON.stringify(pais));
    }
    getNumLogin() {
        if (localStorage.getItem('numLogin')) {
            return parseInt(localStorage.getItem('numLogin'));
        } else {
            this.setNumLogin(1);
            return 0;
        }
    }

    setNumLogin(num) {
        localStorage.setItem('numLogin', num);
    }
}

