export const environment = {
    production: false,
    //API_BASE_PATH: 'https://gestion.rubixseguridad.com',
    //WHITE_LISTED_DOMAINS: ['gestion.rubixseguridad.com'],
    //URI_PRODUCTOS: 'https://gestion.rubixseguridad.com/uploads/productos',
     API_BASE_PATH: 'https://rubix.merkatu.info',
     WHITE_LISTED_DOMAINS: ['rubix.merkatu.info'],
     URI_PRODUCTOS: 'https://rubix.merkatu.info/uploads/productos',
    FIREBASE: {
        // your firebase web config
        apiKey: 'AIzaSyDXNQUvb__Gt9AGL0xlF1sBVnkB2-MNQtc',
        authDomain: 'rubix-dev-20e9b.firebaseapp.com',
        databaseURL: 'https://rubix-dev-20e9b.firebaseio.com',
        projectId: 'rubix-dev-20e9b',
        storageBucket: 'rubix-dev-20e9b.appspot.com',
        messagingSenderId: '568825651334'
    },
};

// VERSIÓN INGLÉS
/*
export const environment = {
    production: false,
    API_BASE_PATH: 'https://administrator.rubixseguridad.com',
    WHITE_LISTED_DOMAINS: ['administrator.rubixseguridad.com'],
    URI_PRODUCTOS: 'https://administrator.rubixseguridad.com/uploads/productos',
    // API_BASE_PATH: 'https://rubix.merkatu.info',
    // WHITE_LISTED_DOMAINS: ['rubix.merkatu.info'],
    // URI_PRODUCTOS: 'https://rubix.merkatu.info/uploads/productos',
    FIREBASE: {
        // your firebase web config
        apiKey: 'AIzaSyDXNQUvb__Gt9AGL0xlF1sBVnkB2-MNQtc',
        authDomain: 'rubix-dev-20e9b.firebaseapp.com',
        databaseURL: 'https://rubix-dev-20e9b.firebaseio.com',
        projectId: 'rubix-dev-20e9b',
        storageBucket: 'rubix-dev-20e9b.appspot.com',
        messagingSenderId: '568825651334'
    },
};
*/
