/**
 * Rubix API
 * API para la alimentación de datos de la APP
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent, HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { Epi } from '../model/epi';
import { InlineResponse2001 } from '../model/inlineResponse2001';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { GlobalService } from 'src/app/services/global.service';



@Injectable({
    providedIn: 'root'
})
export class EPIService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(private global: GlobalService, protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (this.global.getPais()) {
            basePath = this.global.getPais().url;
        } else if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }

        }
        this.configuration.basePath = basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    /**
     * @param id Identificador de la familia
     * @param filtro Códigos separados por comas
     * @param page Número de página
     * @param limit Número de elementos por página
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiEpiByFamiliaIdGet(id: number, filtro?: string, page?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public apiEpiByFamiliaIdGet(id: number, filtro?: string, page?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public apiEpiByFamiliaIdGet(id: number, filtro?: string, page?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public apiEpiByFamiliaIdGet(id: number, filtro?: string, page?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiEpiByFamiliaIdGet.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (filtro !== undefined && filtro !== null) {
            queryParameters = queryParameters.set('filtro', <any>filtro);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<InlineResponse2001>(`${this.configuration.basePath}/api/epi/by/familia/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Devuelve la informacion relativa al EPI
     * @param id Identificador del EPI
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiEpiIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Epi>;
    public apiEpiIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Epi>>;
    public apiEpiIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Epi>>;
    public apiEpiIdGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiEpiIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<Epi>(`${this.configuration.basePath}/api/epi/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
