import { Injectable } from '@angular/core';
import { AuthService, InlineResponse200, InlineResponse201, UserService, Usuario } from '../rest';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from './firebase.service';
import { ToastController } from '@ionic/angular';
import { GlobalService } from './global.service';

export class PreAuth implements InlineResponse200 {
    acceso: number;
    validado: boolean;

    blackListed: boolean;


    constructor(inlineResponse200: InlineResponse200) {
        Object.assign(this, inlineResponse200);
    }
}


export class RegisterData {
    aceptoPolitica: boolean;
    apellidos: string;
    departamento: number;
    email: string;
    nombre: string;
    nombreEmpresa: string;
    operarios: number;
    comercial: number;
    pais: number;
    password: string;
    passwordConfirm: string;
    provincia: number;
    puesto: number;
}

export class PasswordData {
    antigua: string;
    nueva: string;
    confirmarNueva: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public procesoLogin = false;


    constructor(private authService: AuthService, private userService: UserService, private storage: Storage, private router: Router
        , private route: ActivatedRoute
        , private firebaseService: FirebaseService,
        private toastController: ToastController,
        private global: GlobalService) {

    }

    get currentUser(): Observable<Usuario> {
        if (!this._currentUser) {
            return this.userService.apiAuthUserGet().pipe(
                switchMap(usuario => {
                    this._currentUser = usuario;
                    if (usuario.tipoUsuario == 1) {
                        this.firebaseService.saveToken().subscribe();
                    }
                    return of(this._currentUser);
                })
            )
        }
        return of(this._currentUser);
    }
    public getCurrentUser() {
        return this._currentUser;
    }
    private datosPerfilUser = null;

    preAuth: PreAuth;
    email: string;
    codigo: string;

    private _currentUser: Usuario;
    getDatosPerfilUser() {
        return this.datosPerfilUser;
    }
    setDatosPerfilUser(datos) {
        this.datosPerfilUser = datos;
    }

    updateCurrentUser(): Observable<Usuario> {
        return this.userService.apiAuthUserGet().pipe(
            switchMap(usuario => {
                this._currentUser = usuario;
                if (usuario.tipoUsuario == 1) {
                    this.firebaseService.saveToken().subscribe();
                }
                return of(this._currentUser);
            })
        );
    }


    setToken(authToken) {
        return from(this.storage.ready())
            .pipe(
                switchMap(() => {
                    return from(this.storage.set('Authentication', authToken));
                })
            );
    }

    login(email: string, password?: string) {
        let authToken;
        return this.authService.apiAuthLoginPost(email, password).pipe(
            take(1),
            switchMap((token) => {
                authToken = token;
                return this.setToken(authToken);
            }
            ),
            switchMap(() => of(authToken))
        );
    }

    getToken() {
        return from(this.storage.ready())
            .pipe(
                switchMap(() => {
                    return from(this.storage.get('Authentication'));
                })
            );
    }

    removeToken() {
        localStorage.clear();
        return from(this.storage.ready())
            .pipe(
                switchMap(() => {
                    return from(this.storage.clear());
                })
            );
    }

    logout() {
        this.email = null;
        this._currentUser = null;
        this.setDatosPerfilUser(null);

        this.removeToken().subscribe(() => {
            this.router.navigate([this.global.getPlt() + '']);
        });
    }

    redirect() {
        // Si esta en la lista negra no le damos opcion de registro
        if (this.preAuth.blackListed || (this.preAuth.acceso == 0 && this.preAuth.validado == false)) {
            this.login(this.email);
        } else {
            switch (this.preAuth.acceso) {
                // Si esta registrado le llevamos al login para que introduzca su contraseña
                case 1:
                    this.router.navigate([this.global.getPlt() + '/login']);
                    break;
                default:
                case 0:
                    // Si no esta registrado le llevamos a un pantall para empujarle al registro, o bien el acceso como invitado
                    this.router.navigate([this.global.getPlt() + '/pre-access']);
                    break;
            }
        }
    }

    register(registerData: RegisterData) {
        let resp;
        return this.authService.apiAuthRegisterPost(registerData)
            .pipe(
                take(1)
                , switchMap(
                    (response) => {
                        resp = response;
                        return this.setToken(response.accessToken)
                    }
                ),
                switchMap(() => of(resp))
            );
    }

    updateUser(registerData: RegisterData) {
        let resp;
        return this.userService.apiAuthUserUpdatePost(registerData)
            .pipe(
                take(1)
                , switchMap(
                    (response) => {
                        resp = response;
                        return this.setToken(response);
                    }
                ),
                switchMap(() => of(resp))
            );
    }

    updatePassword(passData: PasswordData) {
        let resp;
        return this.userService.apiAuthPasswordUpdatePost(passData)
            .pipe(
                take(1)
                , switchMap(
                    (response) => {
                        resp = response;
                        console.log(response);
                        return this.setToken(response);
                    }
                ),
                switchMap(() => of(resp))
            );
    }

    loginCheck(email: string) {
        this.email = email;
        this.authService.apiAuthLoginCheckPost(this.email).pipe(
            take(1)
        ).subscribe((res) => {
            this.preAuth = new PreAuth(res);
            this.redirect();
        });
    }
}
