/**
 * Rubix API
 * API para la alimentación de datos de la APP
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent, HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { InlineObject } from '../model/inlineObject';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse201 } from '../model/inlineResponse201';
import { InlineResponse202 } from '../model/inlineResponse202';
import { InlineResponse401 } from '../model/inlineResponse401';
import { InlineResponse4011 } from '../model/inlineResponse4011';
import { InlineResponse404 } from '../model/inlineResponse404';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { InlineObject2 } from '../model/inlineObject2';
import { InlineResponse2002, Pais } from '../model/models';
import { GlobalService } from 'src/app/services/global.service';



@Injectable({
    providedIn: 'root'
})
export class AuthService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(private global: GlobalService, protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (this.global.getPais()) {
            basePath = this.global.getPais().url;
        } else if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }

        }
        this.configuration.basePath = basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Comprueba si es un usuario dado de alta. En cas ocontrario
     * @param email Cuenta de correo
     * @param idioma Idioma del usuario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthLoginCheckPost(email: string, idioma?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public apiAuthLoginCheckPost(email: string, idioma?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public apiAuthLoginCheckPost(email: string, idioma?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public apiAuthLoginCheckPost(email: string, idioma?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling apiAuthLoginCheckPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: this.encoder });
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }
        if (idioma !== undefined) {
            formParams = formParams.append('idioma', <any>idioma) as any || formParams;
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/api/auth/login_check`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param email Cuenta de correo
     * @param password Contraseña
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthLoginPost(email: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAuthLoginPost(email: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAuthLoginPost(email: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAuthLoginPost(email: string, password?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling apiAuthLoginPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: this.encoder });
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/api/auth/login`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Comprueba si es un usuario dado de alta. En cas ocontrario
     * @param email Cuenta de correo
     * @param idioma Idioma del usuario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthPasswordRecovery(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAuthPasswordRecovery(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAuthPasswordRecovery(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAuthPasswordRecovery(email: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: this.encoder });
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }

        return this.httpClient.post<InlineResponse2002>(`${this.configuration.basePath}/api/public/password/recovery`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Comprueba si es un usuario dado de alta. En cas ocontrario
     * @param email Cuenta de correo
     * @param idioma Idioma del usuario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthPasswordCode(codigo: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAuthPasswordCode(codigo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAuthPasswordCode(codigo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAuthPasswordCode(codigo: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: this.encoder });
        }

        if (codigo !== undefined) {
            formParams = formParams.append('codigo', <any>codigo) as any || formParams;
        }

        return this.httpClient.post<InlineResponse2002>(`${this.configuration.basePath}/api/public/password/codigo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Comprueba si es un usuario dado de alta. En cas ocontrario
     * @param email Cuenta de correo
     * @param idioma Idioma del usuario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthPassRecUpdate(pass: string, confirm: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAuthPassRecUpdate(pass: string, confirm: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAuthPassRecUpdate(pass: string, confirm: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAuthPassRecUpdate(pass: string, confirm: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: this.encoder });
        }

        if (pass !== undefined) {
            formParams = formParams.append('nueva', <any>pass) as any || formParams;
        }
        if (confirm !== undefined) {
            formParams = formParams.append('confirmarNueva', <any>confirm) as any || formParams;
        }
        console.log(headers);
        console.log(formParams);
        return this.httpClient.post<InlineResponse2002>(`${this.configuration.basePath}/api/auth/user/update/password`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthRegisterPost(data: InlineObject, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse201>;
    public apiAuthRegisterPost(data: InlineObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse201>>;
    public apiAuthRegisterPost(data: InlineObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse201>>;
    public apiAuthRegisterPost(data: InlineObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling apiAuthRegisterPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse201>(`${this.configuration.basePath}/api/auth/register`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Quita el dispositivo seleccionado del listado de dispositivos disponibles para el usuario
     * @param id El id del dispositivo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthUserLogoutPost(id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAuthUserLogoutPost(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAuthUserLogoutPost(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAuthUserLogoutPost(id?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: this.encoder });
        }

        if (id !== undefined) {
            formParams = formParams.append('id', <any>id) as any || formParams;
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/auth/user/logout`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
