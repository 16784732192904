import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginGuard} from './interceptors/login.guard';

const routes: Routes = [
    {
        path: 'app',
        loadChildren: () => import('./paginas/app/public/inicio/inicio.module').then(m => m.InicioPageModule),
    },
    {
        path: 'web',
        loadChildren: () => import('./paginas/web/public/inicio-web/inicio-web.module').then(m => m.InicioWebPageModule),
    },



    // {path: 'login', loadChildren: './login/login.module#LoginPageModule'},
    // {path: 'registro', loadChildren: './registro/registro.module#RegistroPageModule'},
    // {path: 'noticia', loadChildren: './paginas/noticia/noticia.module#NoticiaPageModule'},
    // {path: 'registro-error', loadChildren: './registro-error/registro-error.module#RegistroErrorPageModule'},
    // {path: 'ficha-producto', loadChildren: './paginas/ficha-producto/ficha-producto.module#FichaProductoPageModule'},
    // {path: 'ficha-servicio', loadChildren: './paginas/ficha-servicio/ficha-servicio.module#FichaServicioPageModule'},
    // {
    //     path: 'listado-servicios',
    //     loadChildren: './paginas/listado-servicios/listado-servicios.module#ListadoServiciosPageModule'
    // },
    // {
    //     path: 'listado-resultados',
    //     loadChildren: './paginas/listado-resultados/listado-resultados.module#ListadoResultadosPageModule'
    // },
    // {path: 'normativa', loadChildren: './paginas/normativa/normativa.module#NormativaPageModule'},
    // {path: 'idiomas', loadChildren: './paginas/idiomas/idiomas.module#IdiomasPageModule'},

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
